@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #f7f4ed;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 100vh;
    border: 1px solid transparent;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }
}

@layer components {
  .card-header {
    @apply px-6 py-5 text-lg font-semibold text-[#101828] border-b-[1px] border-gray-200;
  }

  .custom-input {
    @apply py-2 px-4 bg-white text-sm text-gray-800 disabled:bg-[#EFEFEF] border-[1px] border-gray-200 rounded-md focus-visible:!outline-none focus:!outline-none !shadow-none !ring-0 focus:!border-gray-200;
  }

  .custom-label {
    @apply text-gray-800 font-semibold text-sm;
  }

  .custom-radio-button {
    @apply border-gray-300 checked:bg-primary-600 checked:hover:bg-primary-600 checked:active:bg-primary-600 checked:focus:bg-primary-600 focus:bg-primary-600 focus:outline-none focus:ring-1 focus:ring-primary-600;
  }

  .spinner-wrapper {
    @apply absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%];
  }

  .spinner {
    @apply animate-spin text-primary-700 text-6xl;
  }

  .title-text {
    @apply text-gray-900 mb-0 font-semibold text-lg;
  }

  .text-gray-base {
    @apply text-gray-900 text-base;
  }

  .custom-alert-warning {
    @apply alert alert-warning text-warning-700 bg-warning-25 border-warning-300 rounded-xl backdrop-blur-2xl border-[1px] mb-5;
  }

  .custom-alert-error {
    @apply alert alert-error rounded-xl backdrop-blur-2xl border-[1px];
  }

  .button-primary {
    @apply text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700;
  }

  .button-primary.with-loading {
    @apply disabled:text-white disabled:border-primary-300 disabled:bg-primary-300;
  }

  .button-error {
    @apply text-white border-error-500 bg-error-500 hover:bg-error-600 hover:border-error-600;
  }

  .button-error.with-loading {
    @apply disabled:text-white disabled:border-error-300 disabled:bg-error-300;
  }

  .button-error-outline {
    @apply text-error-700 border-error-300 bg-white hover:bg-white hover:border-error-300;
  }

  .button-secondary-outline {
    @apply text-gray-700 border-gray-300 bg-white hover:bg-white hover:border-gray-400;
  }

  .button-blue {
    @apply text-white border-blue-600 bg-blue-600 hover:bg-blue-700 hover:border-blue-700;
  }

  .button-warning {
    @apply text-white border-orange-600 bg-orange-600 hover:bg-orange-700 hover:border-orange-700;
  }
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.swiper {
  width: 100%;
  height: 100%;
  background: #000;
}

.swiper-slide {
  font-size: 18px;
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 60px;
}

.parallax-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 130%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}

.swiper-slide .title {
  font-size: 41px;
  font-weight: 300;
}

.swiper-slide .subtitle {
  font-size: 21px;
}

.swiper-slide .text {
  font-size: 14px;
  max-width: 400px;
  line-height: 1.3;
}

.react-datepicker__input-container {
  height: 100%;
}

.ql-toolbar.ql-snow {
  background-color: #eaecf0;
  color: #1d2939;
  border-bottom: 0px;
  border: 1px solid #eaecf0;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.ql-container.ql-snow {
  border-top: 0px;
  border: 1px solid #eaecf0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background: #ffffff;
  min-height: inherit;
}

.ql-container.ql-snow .ql-editor {
  min-height: inherit;
}

.quill.has-error .ql-toolbar.ql-snow {
  border-color: #f04438 !important;
}

.quill.has-error .ql-container.ql-snow {
  border-color: #f04438 !important;
}

.my-select__input {
  outline: none !important;
  box-shadow: none !important;
  color: #98a2b3 !important;
}
.my-select__input:focus {
  outline: none !important;
  box-shadow: none !important;
}
.react-datepicker-popper {
  z-index: 12 !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="0.75em"]::before {
  content: "Small";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="1em"]::before {
  content: "Normal";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="1.5em"]::before {
  content: "Large";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="2.5em"]::before {
  content: "Huge";
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="0.75em"]::before {
  content: "Small";
  font-size: 0.75em !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="1em"]::before {
  content: "Normal";
  font-size: 1em !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="1.5em"]::before {
  content: "Large";
  font-size: 1.5em !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="2.5em"]::before {
  content: "Huge";
  font-size: 2.5em !important;
}

.react-datepicker__day {
  color: #344054 !important;
  position: relative;
}

.react-datepicker__day--outside-month {
  color: #667085 !important;
}

.react-datepicker__day--disabled {
  color: #ccc !important;
  cursor: not-allowed !important;
}

.react-datepicker__day:hover {
  border-radius: 20px !important;
}

.react-datepicker__day--today {
  border-radius: 20px !important;
  background-color: #f2f4f7 !important;
}

.react-datepicker__day--today:after {
  content: "";
  display: inline-block;
  bottom: 3px;
  left: 50%;
  border-radius: 20px;
  background-color: #00bd52 !important;
  height: 4px;
  width: 4px;
  position: absolute;
  transform: translate(-50%, 0);
}

.react-datepicker__day--selected {
  border-radius: 20px !important;
  background-color: #00bd52 !important;
  color: #fff !important;
  font-weight: 700 !important;
}

.react-datepicker__header {
  background: #fff !important;
}

.react-datepicker__current-month {
  color: #344054 !important;
  font-weight: 600 !important;
}

.react-datepicker__navigation-icon:before {
  border-color: #667085 !important;
}

.react-datepicker__day-name {
  color: #344054 !important;
}

.react-datepicker__navigation {
  top: 9px !important;
}

.steps .step-history:after {
  color: #fff !important;
  background-color: #00bd52 !important;
}

.steps .step-history:before {
  width: 5px !important;
  background-color: #00bd52 !important;
}
